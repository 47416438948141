import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const currentYear = new Date().getFullYear();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            {/* <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/achievements"
                color="text.primary"
                variant={'subtitle2'}
              >
                Achievements
              </Link>
            </Box> */}
            {/* <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/facilities"
                color="text.primary"
                variant={'subtitle2'}
              >
                Facilities
              </Link>
            </Box> */}
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/careers/"
                color="text.primary"
                variant={'subtitle2'}
              >
                Careers
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/virtual-tour"
                color="text.primary"
                variant={'subtitle2'}
              >
                Virtual Tour
              </Link>
            </Box>
          </Box>
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
       
            <Box marginTop={1} marginRight={2}>
            <Link
                underline="none"
                component="a"
                href="/facilities"
                color="text.primary"
                variant={'subtitle2'}
              >
                Facilities
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href="/contact-us"
                color="text.primary"
                variant={'subtitle2'}
              >
                Contact Us
              </Link>
             </Box>

             <Box>
              <Link
          href="https://www.facebook.com/p/National-Public-School-Yeshwanthpur-100084410342747/"
          color="text.primary"
          target="_blank"
          rel="noopener"
          sx={{ marginLeft: 2 }}
        >
          <FacebookIcon/>
        </Link>
        <Link
          href="https://www.instagram.com/nps_ypr/"
          color="text.primary"
          target="_blank"
          rel="noopener"
          sx={{ marginLeft: 2 }}
        >
          <InstagramIcon/>
        </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
             Phone : <a href = "tel:+91 8029501184">+91 8029501184</a>
          , <a href = "tel:+91 8023571220">+91 8023571220</a>
          <br/>
          Email : <a href = "mailto:info@npsypr.edu.in">info@npsypr.edu.in</a>
          , <a href = "mailto:admissions@npsypr.edu.in">admissions@npsypr.edu.in</a>
        </Typography>
        <Typography
          align={'center'}
          // variant={'subtitle2'}
          // color="text.secondary"
          // component={'p'}
          fontSize = "x-small"
          
        >
          {/* Current Year: {currentYear} */}
          © 2013 - {currentYear} by NPS Yeshwanthpur. All Rights Reserved.
          Designed by <span style={{color:'#E31E25'}}>K</span><span style={{color:'#19A9E4'}}>N</span><span style={{color:'#67AE3B'}}>R</span> Tech Solutions for SZI.
          {/* phone : <a href = "tel:+91 8029501184">+91 8029501184</a>
          , <a href = "tel:tel:+91 8023571220">+91 8023571220</a>
          <br/>
          Email : <a href = "mailto:info@npsypr.edu.in">info@npsypr.edu.in</a>
          , <a href = "admissions@npsypr.edu.in">admissions@npsypr.edu.in</a> */}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
